<template>
  <header class="header">
    <div>
      <img
        class="header_img"
        :src="logoUrl"
        alt="澳门TV" />
    </div>
    <button
      class="header_download"
      @click="download">使用Chrome或UC浏览器<br/>开启本站获得最佳体验
    </button>
  </header>
</template>

<script>
import { device, deviceType } from '@/utils'
export default {
  name: 'Header',
  setup (props, { emit }) {
    const {
      IOS_URL,
      ANDROID_URL,
      LOGO_URL
    } = window.__CONFIG__

    const download = () => {
      const deviceName = device()
      switch (deviceName) {
        case deviceType.android:
          window.open(ANDROID_URL, '_self')
          break

        case deviceType.ios:
          window.open(IOS_URL, '_self')
          break

        case deviceType.pc:
          emit('download')
          break
      }
    }

    return {
      download,
      logoUrl: LOGO_URL
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  width: 100%;

  &_img {
    width: 100%;
    vertical-align: bottom;
  }

  &_download {
    position: absolute;
    top: 50%;
    right: 14px;
    z-index: 99;
    padding: 7px 10px;
    font-family: Microsoft Sans serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    color: #388778;
    text-align: center;
    cursor: pointer;
    background: radial-gradient(100% 100% at 50% 0%, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.8) 100%);
    border: 0.4px solid #fff;
    border-radius: 20px;
    outline: none;
    box-shadow: 0 3.38px 3.38px 0 rgba(0, 0, 0, 0.1), 0 1.69px 3.38px 0 rgba(255, 255, 255, 0.5) inset, 0 0 3.38px 0 rgba(255, 255, 255, 0.5);
    transform: translateY(-50%);
    backdrop-filter: blur(5.07px);

    @media screen and (max-width: 320px) {
      padding: 5px 10px;
    }
  }
}
</style>
