<template>
  <div class="tab">
    <input
      id="expand"
      type="checkbox"
      class="tab_expand_checkbox" />
    <div class="tab_container">
      <ul class="tab_list">
        <li
          v-for="(tab, index) of list"
          :key="tab.id"
          class="tab_list_item"
          :class="{ 'tab_list_item-active': active === tab.id }"
          @click="$emit('click', { id: tab.id, index: index })">
          {{ tab.name }}
        </li>
      </ul>
    </div>
    <label
      for="expand"
      class="tab_expand_icon">
      <img
        class="tab_expand_icon_down"
        src="@/assets/image/arrow-down.svg"
        alt="down"/>
      <img
        class="tab_expand_icon_up"
        src="@/assets/image/arrow-up.svg"
        alt="up"/>
    </label>
  </div>
</template>

<script>

export default {
  name: 'Tab',
  props: {
    list: {
      type: Array,
      require: true
    },
    active: {
      type: [String, Number],
      require: true
    }
  }
}
</script>

<style lang="scss" scoped>
.tab {
  position: relative;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  &_container {
    flex: 1;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      height: 0;
    }
  }

  &_list {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;

    &_item {
      box-sizing: border-box;
      flex-shrink: 0;
      min-width: 35px;
      height: 38px;
      padding: 0 16px;
      font-size: 14px;
      line-height: 38px;
      color: $tab-text;
      text-align: center;
      cursor: pointer;

      &-active {
        position: relative;
        color: $tab-active;
        background: linear-gradient(180deg, rgba(1, 195, 129, 0) 0%, rgba(1, 195, 129, 0.4) 100%);

        &::after {
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 100%;
          height: 1px;
          content: "";
          background: $tab-active-border;
          border-radius: 1.5px;
          transform: translateX(-50%);
        }
      }
    }
  }

  &_expand_icon {
    display: flex;
    align-items: center;
    padding: 0 16px;

    > img {
      width: 20px;
      height: 20px;
    }

    &_up {
      display: none;
    }
  }

  &_expand_checkbox {
    position: absolute;
    opacity: 0;

    &:checked {
      ~ .tab_container {
        .tab_list {
          flex-wrap: wrap;
          padding: 0;

          &_item {
            box-sizing: border-box;
            min-width: 20%;
            padding: 0 5px;
          }
        }
      }

      ~ .tab_expand_icon {
        .tab_expand_icon_down {
          display: none;
        }

        .tab_expand_icon_up {
          display: block;
        }
      }
    }
  }
}
</style>
