export const deviceType = {
  android: 'android',
  ios: 'ios',
  pc: 'pc'
}

export function device () {
  if (/android/i.test(navigator.userAgent)) {
    return deviceType.android
  } else if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
    return deviceType.ios
  } else {
    return deviceType.pc
  }
}

/**
 * 當廣告連結子域名為 rand 時，更換為 MMDDhh + 每 30 分鐘
 */
export function randomSubDomain (url) {
  const padZero = n => ('00' + n).slice(-2)
  const timeHash = () => {
    const now = new Date()
    return padZero(now.getUTCMonth() + 1) + padZero(now.getUTCDate()) + padZero(now.getUTCHours()) + Math.floor(now.getUTCMinutes() / 30)
  }
  return url.replace('//rand.', `//${timeHash()}.`)
}
