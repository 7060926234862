<template>
  <div class="home">
    <div class="wrap">
      <base-header @download="switchDialogVisible(true)" />
      <category-app :data="localData" />
      <banner :banner="data.banner" />
      <other-app :other-app="data.otherApp" />
    </div>
    <dialog-qrcode
      v-if="dialogVisible"
      :url="localUrl"
      @close="switchDialogVisible(false)" />
  </div>
</template>

<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import useIndexInfo from '@/composables/useIndexInfo'
import BaseHeader from '@/components/Header'
import CategoryApp from '@/components/category-app/Index'
import Banner from '@/components/Banner'
import OtherApp from '@/components/other-app/Index'
import DialogQrcode from '@/components/DialogQrcode'

export default {
  name: 'Home',
  components: {
    BaseHeader,
    CategoryApp,
    Banner,
    OtherApp,
    DialogQrcode
  },
  setup () {
    const dialogVisible = ref(false)

    const switchDialogVisible = (boolean) => {
      dialogVisible.value = boolean
    }

    let localData = null
    try {
      localData = window.__CONFIG__.DATA ? JSON.parse(window.__CONFIG__.DATA) : null
    } catch (err) {
      console.error('window.__CONFIG__.DATA JSON parse error:', err)
    }

    const data = ref({})

    onBeforeMount(async () => {
      data.value = await useIndexInfo(localData)
    })

    return {
      localUrl: window.location.origin,
      dialogVisible,
      switchDialogVisible,
      data,
      localData
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  min-width: 320px;
  max-width: 500px;
  margin: 0 auto;
  overflow: hidden;
  background: $bg;
}
</style>
