import { request } from '@/utils/request'

export async function getIndexInfo () {
  try {
    return await request.get('/api/index/index')
  } catch (err) {
    return {
      general_app_list: [],
      gold_banner: [],
      hot_app_list: [],
      scroll_list: [],
      top_banner: []
    }
  }
}

export async function getTabList () {
  try {
    return await request.get('/api/index/getCateList')
  } catch (err) {
    return {
      list: []
    }
  }
}

export async function getCategoryApp (id) {
  try {
    return await request.get(`/api/index/getAppList?cid=${id}`)
  } catch (err) {
    return {
      list: []
    }
  }
}
