<template>
  <div class="other_app">
    <app-card
      v-for="(item,index) in otherApp"
      :key="index"
      :img="item.img"
      :title="item.title"
      :scontent="item.scontent"
      :android_url="item.android_url"
      :ios_url="item.ios_url"
      :invented_count="item.invented_count"></app-card>
  </div>
</template>

<script>
import AppCard from './AppCard'
export default {
  name: 'OtherApp',
  components: {
    AppCard
  },
  props: {
    otherApp: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
  .other_app {
    padding: 4px 15px;
  }
</style>
