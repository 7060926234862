<template>
  <div class="app_image">
    <img
      v-if="loading"
      class="app_image_item"
      :class="customClass"
      :src="require('@/assets/image/download.png')" />
    <img
      v-show="!loading"
      :src="src"
      :alt="alt"
      class="app_image_item"
      :class="customClass"
      @load="onLoad">
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
export default {
  name: 'app-image',
  props: {
    src: {
      type: [String, null],
      default: null
    },
    alt: {
      type: String,
      default: ''
    },
    customClass: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const loading = ref(true)

    const onLoad = () => {
      loading.value = false
    }

    return {
      loading,
      onLoad
    }
  }
}
</script>

<style lang="scss" scoped>
.app_image {
  width: 100%;
  height: 100%;

  &_item {
    width: 100%;
    height: 100%;
  }
}
</style>
