import { onMounted, ref, set } from '@vue/composition-api'
import { getTabList, getCategoryApp } from '@/api/index'

export default function useCategoryApp (categoryAppList) {
  const tabActive = ref(0)
  const tabList = ref([])
  const categoryApp = ref({})

  onMounted(async () => {
    if (categoryAppList) {
      const tabs = []
      const apps = {}
      categoryAppList.forEach((item, index) => {
        const id = index
        tabs.push({ id, name: item.name })
        apps[id] = item.list.map((app, index) => {
          return {
            ...app,
            id: index,
            android_url: app.android_url,
            ios_url: app.ios_url
          }
        })
      })
      tabList.value = tabs
      categoryApp.value = apps
    } else {
      await loadTabList()
      tabActive.value = tabList.value[0] ? tabList.value[0].id : 0
      await loadCategoryApp(tabActive.value)
    }
  })

  const loadTabList = async () => {
    const res = await getTabList()
    tabList.value = res.list.map(tab => {
      return {
        id: tab.id,
        name: tab.name
      }
    })
  }

  const loadCategoryApp = async (id) => {
    const res = await getCategoryApp(id)
    const list = res.list.map((app) => {
      return {
        id: app.id,
        android_url: app.android_url,
        ios_url: app.ios_url,
        title: app.title,
        img: app.img
      }
    })
    set(categoryApp.value, id, list)
  }
  return {
    tabList,
    categoryApp,
    tabActive,
    loadCategoryApp
  }
}
