<template>
  <div
    class="app_card"
    @click="openLink">
    <div class="app_icon">
      <app-image
        customClass="app_icon_img"
        :src="img"
        :alt="title"/>
    </div>
    <div class="app_content">
      <div class="app_content_title">{{title}}</div>
      <div class="app_content_desc"><p>{{scontent}}</p></div>
      <div class="app_content_times">下载次数：{{invented_count}}次</div>
    </div>
    <div class="app_download">
      <span class="download_button">下载</span>
    </div>
  </div>
</template>

<script>
import { toRefs } from '@vue/composition-api'
import { device, deviceType, randomSubDomain } from '@/utils'
import AppImage from '../AppImage.vue'
export default {
  name: 'AppCard',
  components: { AppImage },
  props: {
    img: String,
    title: String,
    scontent: String,
    android_url: String,
    ios_url: String,
    invented_count: [String, Number]
  },
  setup (props) {
    const { ios_url: iosUrl, android_url: androidUrl } = toRefs(props)
    const openLink = () => {
      if (device() === deviceType.ios) {
        window.open(randomSubDomain(iosUrl.value), '_blank')
      } else {
        window.open(randomSubDomain(androidUrl.value), '_blank')
      }
    }

    return {
      openLink
    }
  }
}
</script>

<style lang="scss" scoped>
.app {
  &_card {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    height: 107.5px;
    padding: 12px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  &_icon {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 8px;

    /deep/ &_img {
      width: 100%;
      height: 100%;
    }
  }

  &_content {
    display: flex;
    flex: 2;
    flex-direction: column;
    justify-content: center;
    width: 160px;
    margin: 0 18px 0 9px;
    text-align: left;

    &_title {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      color: $other-title;
    }

    &_desc {
      display: flex;
      align-items: center;
      width: 100%;

      p {
        display: -webkit-box;
        margin: 4px 0 8px 0;
        overflow: hidden;
        font-size: 12px;
        line-height: 16px;
        color: $other-description;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    &_times {
      font-size: 12px;
      line-height: 16px;
      color: $other-times;
    }
  }

  &_download {
    display: flex;
    align-items: center;
    width: 18%;
  }
}
</style>
