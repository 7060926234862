<template>
  <div class="category_app">
    <tab
      :list="tabList"
      :active="tabActive"
      @click="tabHandler"/>
    <div class="category_app_container">
      <swiper
        ref="swiperRef"
        @slideChange="slideChange">
        <swiper-slide
          v-for="tab of tabList"
          :key="tab.id">
          <ul
            class="appList">
            <li
              v-for="app of categoryApp[tab.id]"
              :key="app.id"
              class="appList_app">
              <app-card :app="app"/>
            </li>
          </ul>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import Tab from '@/components/category-app/Tab'
import AppCard from '@/components/category-app/AppCard'
import { ref } from '@vue/composition-api'
import useCategoryApp from '@/composables/useCategoryApp'

export default {
  title: 'CategoryApp',
  components: {
    Tab,
    AppCard
  },
  props: {
    data: {
      type: [Object, null],
      default: null
    }
  },
  setup (props) {
    const swiperRef = ref(null)
    const localCategoryApp = props.data ? props.data.categoryApp : null

    const {
      tabList,
      categoryApp,
      tabActive,
      loadCategoryApp
    } = useCategoryApp(localCategoryApp)

    const tabChangeHandler = async (id) => {
      tabActive.value = id
      if (localCategoryApp || categoryApp.value[id]) return false
      await loadCategoryApp(id)
    }

    const tabHandler = async ({ id, index }) => {
      swiperRef.value.swiper.slideTo(index)
    }

    const slideChange = async () => {
      const id = tabList.value[swiperRef.value.swiper.activeIndex].id
      await tabChangeHandler(id)
    }

    return {
      tabList,
      categoryApp,
      tabActive,
      tabHandler,
      swiperRef,
      slideChange
    }
  }
}
</script>

<style lang="scss" scoped>
.category_app_container {
  height: 100%;
  margin-bottom: 15px;

  .swiper-slide {
    height: 1px;
  }

  .swiper-slide-active {
    height: auto;
  }
}

.appList {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;

  &_app {
    width: 16%;
    margin: 10px 4.5% 0;
    list-style: none;
  }
}
</style>
