<template>
  <div class="banner" >
    <swiper
      v-if="banner.length > 0"
      ref="swiper"
      :options="swiperOption">
      <swiper-slide
        v-for="(item, index) in banner"
        :key="index"
        @click.native="openLink(item)">
        <div
          class="banner_container"
          :style="{paddingBottom: `${100 / (750 / 340)}%`}">
          <img
            class="banner_img"
            :src="item.img"/>
        </div>
      </swiper-slide>
      <div
        v-if="swiperOption.pagination"
        slot="pagination"
        class="swiper-pagination"></div>
    </swiper>
  </div>
</template>

<script>
import { reactive } from '@vue/composition-api'
import { device, deviceType, randomSubDomain } from '@/utils'

export default {
  name: 'Banner',
  props: {
    banner: {
      type: Array,
      default: () => []
    }
  },
  setup () {
    const swiperOption = reactive({
      centeredSlides: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets'
      }
    })

    const openLink = (data) => {
      const { ios_url: iosUrl, android_url: androidUrl } = data
      if (device() === deviceType.ios) {
        window.open(randomSubDomain(iosUrl), '_blank')
      } else {
        window.open(randomSubDomain(androidUrl), '_blank')
      }
    }

    return {
      swiperOption,
      openLink
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  &_container {
    position: relative;
    width: 100%;
    height: 0;
  }

  &_img {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

/deep/ .swiper-pagination-bullet-active {
  width: 16px !important;
  background: #fff !important;
  border-radius: 4px !important;
}

/deep/ .swiper-pagination-bullet {
  background-color: #999;
  opacity: 1;
}

</style>
