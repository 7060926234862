<template>
  <div class="dialog">
    <div class="dialog_container">
      <button
        class="dialog_close"
        @click="$emit('close')">关闭</button>
      <div class="dialog_box">
        <canvas
          ref="qrcodeRef"
          class="dialog_box_img"></canvas>
      </div>
      <p class="dialog_text">扫一扫下载澳门TV APP</p>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from '@vue/composition-api'
import QRCode from 'qrcode'

export default ({
  name: 'DialogQrcode',
  props: {
    url: {
      type: String
    }
  },
  setup (props) {
    const qrcodeRef = ref(null)

    const buildQrcode = (el, url) => {
      QRCode.toCanvas(el, url, {
        errorCorrectionLevel: 'M',
        margin: 0
      })
    }

    watchEffect(() => {
      if (props.url) {
        buildQrcode(qrcodeRef.value, props.url)
      }
    })

    return {
      qrcodeRef
    }
  }
})
</script>

<style lang="scss" scoped>
.dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  vertical-align: bottom;
  background-color: rgba(0, 0, 0, 0.5);

  &_container {
    position: relative;
    box-sizing: border-box;
    width: 200px;
    padding: 15px;
    background-color: #fff;
    border-radius: 5px;
  }

  &_close {
    position: absolute;
    top: -35px;
    right: 5px;
    width: 50px;
    height: 26px;
    padding: 0;
    font-size: 14px;
    line-height: 26px;
    color: #000;
    text-align: center;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #999;
    border-radius: 15px;
  }

  &_box {
    width: 100%;
    height: 100%;

    &_img {
      display: block;
      width: 100% !important;
      height: 100% !important;
    }
  }

  &_text {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 0;
    font-size: 14px;
    color: #000;
    text-align: center;
  }
}
</style>
