import { getIndexInfo } from '@/api'

export default async function (configData) {
  let data = {}
  if (configData && configData.otherApp && configData.banner) {
    data = { ...configData, banner: shuffle(configData.banner) }
  } else {
    data = await loadIndexInfo()
  }

  async function loadIndexInfo () {
    const originalData = await getIndexInfo()
    const { general_app_list: otherApp, scroll_list: banner } = originalData
    return { otherApp, banner: shuffle(banner) }
  }

  function shuffle (array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]
    }
    return array
  }

  return data
}
