<template>
  <div class="app_card">
    <a
      class="app_card_clicker"
      @click="openLink"></a>
    <app-image
      customClass="app_card_img"
      :src="app.img"
      :alt="app.title"/>
    <p class="app_card_title">{{app.title}}</p>
    <button class="download_button">下载</button>
  </div>
</template>

<script>
import { device, deviceType, randomSubDomain } from '@/utils'
import AppImage from '../AppImage.vue'

export default {
  name: 'AppCard',
  components: { AppImage },
  props: {
    app: {
      type: Object,
      require: true
    }
  },
  setup (props) {
    const openLink = () => {
      const deviceName = device()
      switch (deviceName) {
        case deviceType.android:
        case deviceType.pc:
          window.open(randomSubDomain(props.app.android_url), '_blank')
          break

        case deviceType.ios:
          window.open(randomSubDomain(props.app.ios_url), '_blank')
          break
      }
    }

    return {
      openLink
    }
  }
}
</script>

<style lang="scss" scoped>
.app_card {
  position: relative;

  &_clicker {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  /deep/ &_img {
    display: block;
    width: 100%;
    border-radius: 13px;
  }

  &_title {
    width: 100%;
    height: 32px;
    margin: 0;
    overflow: hidden;
    font-size: 14px;
    line-height: 32px;
    color: $category-title;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
