import axios from 'axios'

export const request = axios.create({
  baseURL: window.__CONFIG__.API_URL
})

request.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

request.interceptors.response.use(
  response => {
    const { data } = response.data
    return data
  },
  error => {
    return Promise.reject(error)
  }
)
